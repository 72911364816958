import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Input, Select } from 'antd'
import { ImportOutlined } from '@ant-design/icons'

import Button from 'Components/Button'
import { InputSubmitWrapper } from './styled'
import { ITEM_TYPE_BV, ITEM_TYPE_ORDER } from 'Common/constants'

const InputSubmit = props => {
  const { intl, isLoading, defaultType, onSubmit, onSelectChange } = props
  const [orderNumber, setOrderNumber] = useState()

  const selectAfter = (
    <Select defaultValue={defaultType} onChange={onSelectChange}>
      <Select.Option value={ITEM_TYPE_ORDER}>
        <FormattedMessage id={'item.type.order'} />
      </Select.Option>
      <Select.Option value={ITEM_TYPE_BV}>
        <FormattedMessage id={'item.type.bv'} />
      </Select.Option>
    </Select>
  )

  return (
    <InputSubmitWrapper>
      <Input
        maxLength={10}
        placeholder={intl.formatMessage({ id: 'home.placeholder.import' })}
        onPressEnter={() => onSubmit(orderNumber)}
        onChange={e => setOrderNumber(e.target.value)}
        addonAfter={selectAfter}
      />
      <Button
        icon={<ImportOutlined />}
        loading={isLoading}
        onClick={() => onSubmit(orderNumber)}>
        <span>
          <FormattedMessage id={'home.button.import'} />
        </span>
      </Button>
    </InputSubmitWrapper>
  )
}
InputSubmit.propTypes = {
  isLoading: PropTypes.bool,
  defaultType: PropTypes.string,
  onSubmit: PropTypes.func,
  onSelectChange: PropTypes.func
}
export default injectIntl(InputSubmit)
