import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Avatar, Empty, Table } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

import { SmallTitle } from 'Common/styled'
import { colors, DATE_TIME_FORMAT } from 'Common/constants'
import { formatDate } from 'Common/utils'

const LogsTable = props => {
  const { data } = props
  const columns = [
    {
      key: 'action',
      title: <FormattedMessage id={'item.logs.column.action'} />,
      dataIndex: 'action'
    },
    {
      key: 'actionTimeStamp',
      title: <FormattedMessage id={'item.logs.column.timestamp'} />,
      dataIndex: 'actionTimeStamp',
      render: actionTimeStamp => formatDate(actionTimeStamp, DATE_TIME_FORMAT)
    },
    {
      key: 'executedBy',
      title: <FormattedMessage id={'item.logs.column.executedBy'} />,
      dataIndex: 'executedBy'
    },
    {
      key: 'insertedParameters',
      title: <FormattedMessage id={'item.logs.column.insertedParameters'} />,
      dataIndex: 'insertedParameters'
    },
    {
      key: 'result',
      title: <FormattedMessage id={'item.logs.column.result'} />,
      dataIndex: 'result',
      render: result => (
        <Avatar
          size={'small'}
          icon={result ? <CheckOutlined /> : <CloseOutlined />}
          style={{ backgroundColor: result ? colors.success : colors.error }}
        />
      )
    }
  ]
  return (
    <Table
      title={() => (
        <SmallTitle>
          <FormattedMessage id={'item.logs.title'} />
        </SmallTitle>
      )}
      dataSource={data}
      columns={columns}
      rowKey={row => row.Id}
      size={'small'}
      locale={{
        emptyText: (
          <Empty description={<FormattedMessage id={'item.logs.emptyText'} />} />
        )
      }}
    />
  )
}
LogsTable.propTypes = {
  data: PropTypes.array
}
export default LogsTable
