import { GET_STORES } from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const getStores = () => async dispatch => {
  try {
    const res = await api.store.list()
    return dispatch({ type: GET_STORES, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}
