import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Badge, Empty } from 'antd'
import { EyeOutlined } from '@ant-design/icons'

import Button from 'Components/Button'
import { StyledTable } from './styled'

import { formatDate, getShortName } from 'Common/utils'
import { DATE_TIME_FORMAT, hubPayStatusesMap, isMobile } from 'Common/constants'

const ItemsTable = props => {
  const { intl, isLoading, data, rowSelection, openTeammatesModal, viewItem } =
    props
  const columns = [
    {
      key: 'itemNumber',
      title: intl.formatMessage({ id: 'item.table.column.itemNumber' }),
      dataIndex: 'fullNumber',
      sorter: (a, b) => a.fullNumber.localeCompare(b.fullNumber),
      isMobile: true
    },
    {
      key: 'type',
      title: intl.formatMessage({ id: 'item.table.column.type' }),
      dataIndex: 'objectType',
      render: type => intl.formatMessage({ id: `item.type.${type}` }),
      sorter: (a, b) => a.objectType.localeCompare(b.objectType)
    },
    {
      key: 'totalAmount',
      title: intl.formatMessage({ id: 'item.table.column.totalAmount' }),
      dataIndex: 'totalAmount',
      render: totalAmount => `${totalAmount}€`,
      sorter: (a, b) => a.totalAmount - b.totalAmount
    },
    {
      key: 'createdBy',
      title: intl.formatMessage({ id: 'item.table.column.createdBy' }),
      render: (_, row) =>
        getShortName(row.createdByFirstName, row.createdByLastName),
      sorter: (a, b) =>
        getShortName(a.createdByFirstName, a.createdByLastName).localeCompare(
          getShortName(b.createdByFirstName, b.createdByLastName)
        )
    },
    {
      key: 'inChargeOf',
      title: intl.formatMessage({ id: 'item.table.column.inChargeOf' }),
      render: (_, row) =>
        getShortName(row.inChargeOfFirstName, row.inChargeOfLastName),
      sorter: (a, b) =>
        getShortName(a.inChargeOfFirstName, a.inChargeOfLastName).localeCompare(
          getShortName(b.inChargeOfFirstName, b.inChargeOfLastName)
        )
    },
    {
      key: 'date',
      title: intl.formatMessage({ id: 'item.table.column.date' }),
      dataIndex: 'date',
      render: date => formatDate(date, DATE_TIME_FORMAT),
      sorter: (a, b) => a.date.localeCompare(b.date)
    },
    {
      key: 'hubPayStatus',
      title: intl.formatMessage({ id: 'item.table.column.hubPayStatus' }),
      dataIndex: 'hubPayStatus',
      render: (hubPayStatus, row) => (
        <>
          <Badge status={hubPayStatusesMap[hubPayStatus]} />{' '}
          <FormattedMessage
            id={`hubPayStatus.${hubPayStatus}`}
            values={{
              item: intl.formatMessage({ id: `item.type.${row.objectType}` })
            }}
          />
        </>
      ),
      sorter: (a, b) =>
        intl
          .formatMessage({ id: `hubPayStatus.${a.hubPayStatus}` })
          .localeCompare(
            intl.formatMessage({ id: `hubPayStatus.${b.hubPayStatus}` })
          ),
      isMobile: true
    },
    {
      key: 'itemStatus',
      title: intl.formatMessage({ id: 'item.table.column.itemStatus' }),
      dataIndex: 'status',
      render: itemStatus => (
        <FormattedMessage id={`itemStatus.${itemStatus}`} />
      ),
      sorter: (a, b) =>
        intl
          .formatMessage({ id: `itemStatus.${a.status}` })
          .localeCompare(intl.formatMessage({ id: `itemStatus.${b.status}` }))
    },
    {
      key: 'updatedAt',
      title: intl.formatMessage({ id: 'item.table.column.updatedAt' }),
      dataIndex: 'updatedAt',
      render: updatedAt => formatDate(updatedAt, DATE_TIME_FORMAT),
      sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt)
    },
    {
      key: 'action',
      width: 48,
      render: (_, row) => (
        <Button
          icon={<EyeOutlined />}
          onClick={() => viewItem(row.fullNumber, row.objectType)}
        />
      ),
      isMobile: true
    }
  ]

  return (
    <StyledTable
      dataSource={data}
      columns={isMobile ? columns.filter(c => c.isMobile) : columns}
      rowKey={row => `${row.Id}-${row.objectType}`}
      rowSelection={rowSelection}
      pagination={{
        showTotal: () =>
          rowSelection ? (
            <Button
              disabled={rowSelection.selectedRowKeys.length === 0}
              onClick={openTeammatesModal}>
              <FormattedMessage id={'item.table.button.assignToTeammate'} />
            </Button>
          ) : null
      }}
      loading={isLoading}
      locale={{
        triggerAsc: intl.formatMessage({ id: 'item.table.sortAsc' }),
        triggerDesc: intl.formatMessage({ id: 'item.table.sortDesc' }),
        cancelSort: intl.formatMessage({ id: 'item.table.cancelSort' }),
        emptyText: (
          <Empty
            description={intl.formatMessage({ id: 'item.table.emptyText' })}
          />
        )
      }}
    />
  )
}
ItemsTable.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  openTeammatesModal: PropTypes.func,
  rowSelection: PropTypes.object
}
export default injectIntl(ItemsTable)
