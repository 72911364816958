const env = process.env.REACT_APP_ENV
const redirectAfterLogin = encodeURIComponent(window.location.href)
const envConfig = {
  dev: {
    LOGIN_URL: `https://test-lmit-hubpay-nprd-ping-k34tqjimta-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://test-hubpay-backend-core-k34tqjimta-ew.a.run.app/api',
    DATASTUDIO_REPORT_ACCOUNTING_URL:
      'https://datastudio.google.com/embed/reporting/2590ec05-36b2-4039-8b7e-972e0a0d292d/page/p_8rbc50tgpc',
    DATASTUDIO_REPORT_ANIMATION_URL:
      'https://datastudio.google.com/reporting/09b6ac9e-5d19-46d8-a475-83d518e16f8f'
  },
  test: {
    LOGIN_URL: `https://test-lmit-hubpay-nprd-ping-k34tqjimta-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://test-hubpay-backend-core-k34tqjimta-ew.a.run.app/api',
    DATASTUDIO_REPORT_ACCOUNTING_URL:
      'https://datastudio.google.com/embed/reporting/2590ec05-36b2-4039-8b7e-972e0a0d292d/page/p_8rbc50tgpc',
    DATASTUDIO_REPORT_ANIMATION_URL:
      'https://datastudio.google.com/reporting/09b6ac9e-5d19-46d8-a475-83d518e16f8f'
  },
  prod: {
    LOGIN_URL: `https://prod-lmit-hubpay-prd-ping-ureoex5xga-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://prod-hubpay-backend-core-ureoex5xga-ew.a.run.app/api',
    DATASTUDIO_REPORT_ACCOUNTING_URL:
      'https://datastudio.google.com/embed/reporting/2590ec05-36b2-4039-8b7e-972e0a0d292d/page/LyjfC',
    DATASTUDIO_REPORT_ANIMATION_URL:
      'https://datastudio.google.com/reporting/09b6ac9e-5d19-46d8-a475-83d518e16f8f'
  }
}

export default envConfig[env]
