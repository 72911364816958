import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { getReceiptTodoItems, updateItem } from 'Redux/actions/item-actions'

import ItemsTable from 'Components/ItemsTable'
import { PageTitle } from 'Common/styled'

class ReceiptTodo extends Component {
  static propTypes = {
    getReceiptTodoItems: PropTypes.func,
    items: PropTypes.array,
    storeId: PropTypes.number
  }

  state = {
    isLoading: false
  }

  componentDidMount() {
    this.getReceiptTodoItems()
  }
  componentDidUpdate(prevProps, prevState) {
    const { storeId } = this.props
    if (prevProps.storeId !== storeId) this.getReceiptTodoItems()
  }

  getReceiptTodoItems = async () => {
    const { getReceiptTodoItems } = this.props
    this.setState({ isLoading: true })
    await getReceiptTodoItems('archive')
    this.setState({ isLoading: false })
  }

  viewItem = async (itemNumber, type) => {
    const { history, updateItem } = this.props
    await updateItem({ objectType: type })
    return history.push(`/item/${itemNumber}`)
  }

  render() {
    const { isLoading } = this.state
    const { items } = this.props
    return (
      <>
        <PageTitle>
          <FormattedMessage id={'receiptTodo.title'} />
        </PageTitle>
        <ItemsTable isLoading={isLoading} data={items} viewItem={this.viewItem} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  storeId: state.user.current.storeId,
  items: state.item.list
})
const mapDispatchToProps = {
  getReceiptTodoItems,
  updateItem
}
export default connect(mapStateToProps, mapDispatchToProps)(ReceiptTodo)
