import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input } from 'antd'

const CustomerForm = props => {
  const { intl, onChange } = props
  const customer = props.customer || {}
  return (
    <Form
      onValuesChange={onChange}
      layout={'vertical'}
      initialValues={customer}>
      <Form.Item name={'firstName'}>
        <Input
          disabled={customer.firstName}
          placeholder={intl.formatMessage({ id: 'item.placeholder.firstName' })}
        />
      </Form.Item>
      <Form.Item name={'lastName'}>
        <Input
          disabled={customer.lastName}
          placeholder={intl.formatMessage({ id: 'item.placeholder.lastName' })}
        />
      </Form.Item>
      <Form.Item name={'email'}>
        <Input
          disabled={customer.email}
          placeholder={intl.formatMessage({ id: 'item.placeholder.email' })}
        />
      </Form.Item>
      <Form.Item name={'phoneNumber'}>
        <Input
          disabled={customer.phoneNumber}
          placeholder={intl.formatMessage({ id: 'item.placeholder.phoneNumber' })}
        />
      </Form.Item>
    </Form>
  )
}
CustomerForm.propTypes = {
  customer: PropTypes.object,
  onChange: PropTypes.func
}
export default CustomerForm
