import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import Loader from 'Components/Loader'
import { PageTitle } from 'Common/styled'

import config from 'Common/config'

const Report = props => {
  const [isLoading, setLoading] = useState(true)
  const style = {
    width: 1800,
    height: 800,
    display: isLoading ? 'none' : 'block',
    margin: '0 auto'
  }

  return (
    <>
      <PageTitle>
        <FormattedMessage id={'report.title'} />
      </PageTitle>
      {isLoading ? <Loader /> : null}
      <iframe
        title={'report'}
        src={config.DATASTUDIO_REPORT_ACCOUNTING_URL}
        frameBorder={'0'}
        style={style}
        allowFullScreen
        onLoad={() => setLoading(false)}
      />
    </>
  )
}
export default Report
