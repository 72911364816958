import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { message, Modal, Select } from 'antd'
import { connect } from 'react-redux'

import {
  getItems,
  importItem,
  moveItems,
  updateItem
} from 'Redux/actions/item-actions'
import { getTeammates } from 'Redux/actions/user-actions'

import InputSubmit from 'Components/InputSubmit'
import ItemsTable from 'Components/ItemsTable'
import { PageTitle } from 'Common/styled'

class Home extends Component {
  static propTypes = {
    getItems: PropTypes.func,
    getTeammates: PropTypes.func,
    importItem: PropTypes.func,
    moveItems: PropTypes.func,
    item: PropTypes.object,
    items: PropTypes.array,
    storeId: PropTypes.number,
    teammates: PropTypes.array,
    updateItem: PropTypes.func
  }

  state = {
    isLoading: false,
    selectedItemIds: [],
    selectedItems: []
  }

  componentDidMount() {
    const { getTeammates } = this.props
    this.getItems()
    getTeammates()
  }
  componentDidUpdate(prevProps, prevState) {
    const { storeId } = this.props
    if (prevProps.storeId !== storeId) this.getItems()
  }

  getItems = async () => {
    const { getItems } = this.props
    this.setState({ isLoading: true })
    await getItems('active')
    this.setState({ isLoading: false })
  }

  importItem = async itemCode => {
    const { history, storeId, importItem } = this.props
    if (itemCode) {
      this.setState({ isLoading: true })

      const res = await importItem(itemCode, storeId, true)
      if (res?.status === 200) return history.push(`/item/${res.itemFullNumber}`)
      if (res?.status === 202)
        message.error({ content: res.message, duration: 5 })

      this.setState({ isLoading: false })
    }
  }

  moveItems = async () => {
    const { selectedItems, selectedTeammate } = this.state
    const { moveItems } = this.props
    this.setState({ isLoading: true })

    await moveItems(selectedItems, selectedTeammate)
    this.setState({ selectedItemIds: [], selectedItems: [], selectedTeammate: undefined })
    this.getItems()
  }

  openTeammatesModal = () => {
    const { intl } = this.props
    Modal.confirm({
      title: intl.formatMessage({ id: 'home.teammateModal.title' }),
      content: this.renderTeammatesSelect(),
      okText: intl.formatMessage({ id: 'home.teammateModal.okText' }),
      cancelText: intl.formatMessage({ id: 'home.teammateModal.cancelText' }),
      onOk: this.moveItems,
      onCancel: () => this.setState({ selectedTeammate: undefined })
    })
  }

  viewItem = async (itemFullNumber, type) => {
    const { history, updateItem } = this.props
    await updateItem({ objectType: type })
    return history.push(`/item/${itemFullNumber}`)
  }

  renderTeammatesSelect() {
    const { intl, teammates } = this.props
    return (
      <Select
        showSearch
        style={{ width: '100%' }}
        placeholder={intl.formatMessage({ id: 'home.placeholder.teammate' })}
        onChange={value => this.setState({ selectedTeammate: value })}>
        {teammates.map(t => (
          <Select.Option key={t.id}>{t.displayName}</Select.Option>
        ))}
      </Select>
    )
  }

  render() {
    const { isLoading, selectedItemIds } = this.state
    const { items, item, updateItem } = this.props
    return (
      <>
        <PageTitle>
          <FormattedMessage id={'home.title'} />
        </PageTitle>
        <InputSubmit
          isLoading={isLoading}
          defaultType={item.objectType}
          onSubmit={this.importItem}
          onSelectChange={value => updateItem({ objectType: value })}
        />
        <ItemsTable
          isLoading={isLoading}
          data={items}
          rowSelection={{
            selectedRowKeys: selectedItemIds,
            onChange: (keys, rows) =>
              this.setState({ selectedItemIds: keys, selectedItems: rows })
          }}
          openTeammatesModal={this.openTeammatesModal}
          viewItem={this.viewItem}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  storeId: state.user.current.storeId,
  teammates: state.user.teammates,
  items: state.item.list,
  item: state.item.current
})
const mapDispatchToProps = {
  importItem,
  moveItems,
  updateItem,
  getItems,
  getTeammates
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Home))
