import API from './axiosConfig'
import { BU, APPLICATION } from './constants'

export default {
  user: {
    authenticate: token =>
      API.get('/user', { headers: { Authorization: `Bearer ${token}` } }),
    teammates: () => API.get('/teammates', { params: { bu: BU, application: APPLICATION } })
  },
  store: {
    list: () => API.get('/stores', { params: { bu: BU, application: APPLICATION } })
  },
  item: {
    list: (storeId, type) =>
      API.get('/list', { params: { bu: BU, application: APPLICATION, storeId, type } }),
    receiptTodoList: () =>
      API.get('/failedReceiptList', { params: { bu: BU, application: APPLICATION } }),
    move: body =>
      API.post('/item/updateInChargeOf', body, { params: { bu: BU, application: APPLICATION } }),
    import: (itemCode, storeId, log, type) =>
      API.post(
        '/import',
        { itemCode },
        { params: { bu: BU, application: APPLICATION, storeId, log, type } }
      ),
    get: (number, storeId, type) =>
      API.get(`/item/${number}`, { params: { bu: BU, application: APPLICATION, storeId, type } }),
    logs: (number, storeId, type) =>
      API.get(`/item/${number}/logs`, { params: { bu: BU, application: APPLICATION, storeId, type } }),
    stepper: (number, storeId, type) =>
      API.get(`/item/${number}/stepper`, { params: { bu: BU, application: APPLICATION, storeId, type } }),
    choosePaymentMethod: (paymentMethod, number, storeId, type) => 
      API.post(
        `/item/${number}/getPaymentInfo`,
        { paymentMethod },
        { params: { bu: BU, application: APPLICATION, storeId, type } 
      }),
    interventions: (number, storeId, type) =>
      API.get(`/item/${number}/interventions`, {
        params: { bu: BU, application: APPLICATION, storeId, type }
      }),
    fixIntervention: (interventionId, number, storeId, type) =>
      API.post(
        `/item/${number}/fixIntervention`,
        { interventionId },
        { params: { bu: BU, application: APPLICATION, storeId, type } }
      ),
    coupons: (number, storeId, type) =>
      API.get(`/item/${number}/coupons`, { params: { bu: BU, application: APPLICATION, storeId, type } }),
    addCoupon: (coupon, number, storeId, type) =>
      API.put(`/item/${number}/coupon`, coupon, {
        params: { bu: BU, application: APPLICATION, storeId, type }
      }),
    sendPaymentLink: (body, number, storeId, type) =>
      API.post(`/item/${number}/sendPayment`, body, {
        params: { bu: BU, application: APPLICATION, storeId, type }
      }),
    manualSendPaymentLink: (number, storeId, type) =>
      API.post(
        `/item/${number}/manualSendLink`,
        {},
        { params: { bu: BU, application: APPLICATION, storeId, type } }
      ),
    cancelPaymentLink: (number, storeId, type) =>
      API.delete(`/item/${number}/cancelPaymentLink`, {
        params: { bu: BU, application: APPLICATION, storeId, type }
      }),
    createAutomaticReceipt: (number, storeId, type) =>
      API.post(
        `/item/${number}/createReceipt`,
        {},
        { params: { bu: BU, application: APPLICATION, storeId, type } }
      ),
    manualReceipt: (body, number, storeId, type) =>
      API.post(`/item/${number}/manualReceipt`, body, {
        params: { bu: BU, application: APPLICATION, storeId, type }
      }),
    sendReceipt: (body, number, storeId, type) =>
      API.post(`/item/${number}/sendReceipt`, body, {
        params: { bu: BU, application: APPLICATION, storeId, type }
      })
  }
}
