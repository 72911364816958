import {
  IMPORT_ITEM,
  UPDATE_ITEM,
  GET_ITEM,
  GET_ITEM_LOGS,
  GET_ITEM_STEPPER,
  CHOOSE_PAYMENT_METHOD,
  GET_ITEM_INTERVENTIONS,
  FIX_ITEM_INTERVENTION,
  GET_ITEM_COUPONS,
  ADD_COUPON,
  GET_ITEMS,
  MOVE_ITEMS,
  SEND_PAYMENT_LINK,
  MANUAL_SEND_PAYMENT_LINK,
  CANCEL_PAYMENT_LINK,
  CREATE_AUTOMATIC_RECEIPT,
  MANUAL_RECEIPT,
  SEND_RECEIPT
} from 'Redux/action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const importItem =
  (itemCode, storeId, log) => async (dispatch, getState) => {
    const { objectType } = getState().item.current
    try {
      const res = await api.item.import(itemCode, storeId, log, objectType)
      const { status, data } = res
      dispatch({ type: IMPORT_ITEM })
      return { status, message: data.message, itemFullNumber: data.fullNumber }
    } catch (error) {
      return handleServerError(error)
    }
  }

export const updateItem = item => async dispatch => {
  return dispatch({ type: UPDATE_ITEM, payload: item })
}

export const getItem = (number, storeId) => async (dispatch, getState) => {
  const { objectType } = getState().item.current
  try {
    const res = await api.item.get(number, storeId, objectType)
    const { status, data } = res
    dispatch({ type: GET_ITEM, payload: data || {} })
    return {
      status,
      itemNumber: data.itemNumber,
      storeId: data.storeId
    }
  } catch (error) {
    return handleServerError(error)
  }
}

export const getItemLogs = number => async (dispatch, getState) => {
  const { storeId, objectType } = getState().item.current
  try {
    const res = await api.item.logs(number, storeId, objectType)
    return dispatch({ type: GET_ITEM_LOGS, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getItemStepper = number => async (dispatch, getState) => {
  const { storeId, objectType } = getState().item.current
  try {
    const res = await api.item.stepper(number, storeId, objectType)
    return dispatch({ type: GET_ITEM_STEPPER, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const choosePaymentMethod = paymentMethod => async (dispatch, getState) => {
  const { itemNumber, storeId, objectType } = getState().item.current
  try {
    await api.item.choosePaymentMethod(paymentMethod, itemNumber, storeId, objectType)
    return dispatch({ type: CHOOSE_PAYMENT_METHOD })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getItemInterventions = () => async (dispatch, getState) => {
  const { itemNumber, storeId, objectType } = getState().item.current
  try {
    const res = await api.item.interventions(itemNumber, storeId, objectType)
    return dispatch({ type: GET_ITEM_INTERVENTIONS, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const fixItemIntervention =
  interventionId => async (dispatch, getState) => {
    const { itemNumber, storeId, objectType } = getState().item.current
    try {
      const res = await api.item.fixIntervention(
        interventionId,
        itemNumber,
        storeId,
        objectType
      )
      return dispatch({ type: FIX_ITEM_INTERVENTION, payload: res.data })
    } catch (error) {
      return handleServerError(error)
    }
  }

export const getItemCoupons = () => async (dispatch, getState) => {
  const { itemNumber, storeId, objectType } = getState().item.current
  try {
    const res = await api.item.coupons(itemNumber, storeId, objectType)
    dispatch({ type: GET_ITEM_COUPONS, payload: res.data })
    return res.data || []
  } catch (error) {
    return handleServerError(error)
  }
}

export const addCoupon = coupon => async (dispatch, getState) => {
  const { itemNumber, storeId, objectType } = getState().item.current
  try {
    await api.item.addCoupon(coupon, itemNumber, storeId, objectType)
    return dispatch({ type: ADD_COUPON })
  } catch (error) {
    return handleServerError(error)
  }
}

export const moveItems = (items, newInChargeOf) => async dispatch => {
  const filteredItems = items.map(item => ({
    id: item.Id,
    type: item.objectType
  }))

  try {
    await api.item.move({ items: filteredItems, newInChargeOf })
    return dispatch({ type: MOVE_ITEMS })
  } catch (error) {
    return handleServerError(error)
  }
}

export const sendPaymentLink = customer => async (dispatch, getState) => {
  const { itemNumber, storeId, objectType } = getState().item.current
  const body = { customer }
  try {
    await api.item.sendPaymentLink(body, itemNumber, storeId, objectType)
    return dispatch({ type: SEND_PAYMENT_LINK })
  } catch (error) {
    return handleServerError(error)
  }
}

export const manualSendPaymentLink = () => async (dispatch, getState) => {
  const { itemNumber, storeId, objectType } = getState().item.current
  try {
    await api.item.manualSendPaymentLink(itemNumber, storeId, objectType)
    return dispatch({ type: MANUAL_SEND_PAYMENT_LINK })
  } catch (error) {
    return handleServerError(error)
  }
}

export const cancelPaymentLink = () => async (dispatch, getState) => {
  const { itemNumber, storeId, objectType } = getState().item.current
  try {
    await api.item.cancelPaymentLink(itemNumber, storeId, objectType)
    return dispatch({ type: CANCEL_PAYMENT_LINK })
  } catch (error) {
    return handleServerError(error)
  }
}

export const createAutomaticReceipt = () => async (dispatch, getState) => {
  const { itemNumber, storeId, objectType } = getState().item.current
  try {
    await api.item.createAutomaticReceipt(itemNumber, storeId, objectType)
    return dispatch({ type: CREATE_AUTOMATIC_RECEIPT })
  } catch (error) {
    return handleServerError(error)
  }
}

export const manualReceipt = receiptId => async (dispatch, getState) => {
  const { itemNumber, storeId, objectType } = getState().item.current
  const body = { receiptId }
  try {
    await api.item.manualReceipt(body, itemNumber, storeId, objectType)
    return dispatch({ type: MANUAL_RECEIPT })
  } catch (error) {
    return handleServerError(error)
  }
}

export const sendReceipt = customer => async (dispatch, getState) => {
  const { itemNumber, storeId, objectType } = getState().item.current
  const body = { customer }
  try {
    await api.item.sendReceipt(body, itemNumber, storeId, objectType)
    return dispatch({ type: SEND_RECEIPT })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getItems = type => async (dispatch, getState) => {
  const { storeId } = getState().user.current
  try {
    const res = await api.item.list(storeId, type)
    return dispatch({ type: GET_ITEMS, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}
export const getReceiptTodoItems = () => async dispatch => {
  try {
    const res = await api.item.receiptTodoList()
    return dispatch({ type: GET_ITEMS, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}
