export const BU = 5
export const APPLICATION = 'phonesale-clickpay'

export const isMobile = window.innerWidth < 600

export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const ITEM_TYPE_ORDER = 'order'
export const ITEM_TYPE_BV = 'bv'

export const buttonActionsMap = {
  REFRESH: 'getItem',
  CHOOSE_PAYMENT_METHOD: 'openPaymentMethodModal',
  FIX_INTERVENTIONS: 'getItemInterventions',
  ADD_COUPON: 'getItemCoupons',
  SEND_PAYMENT_LINK: 'openCustomerModal',
  MANUAL_SEND_PAYMENT_LINK: 'openLinkModal',
  RETIRE_PAYMENT_LINK: 'cancelPaymentLink',
  AUTOMATIC_RECEIPT: 'createAutomaticReceipt',
  MANUAL_RECEIPT: 'openReceiptModal',
  SEND_RECEIPT: 'openCustomerModal',
  RETURN_TO_LIST: 'navigateToList'
}

const HUBPAY_STATUS_IMPORTED = 'IMPORTED'
const HUBPAY_STATUS_CHOOSE_PAYMENT_METHOD_DONE = 'CHOOSE_PAYMENT_METHOD_DONE'
const HUBPAY_STATUS_CHOOSE_PAYMENT_METHOD_FAIL = 'CHOOSE_PAYMENT_METHOD_FAIL'
const HUBPAY_STATUS_FIX_INTERVENTIONS_DONE = 'FIX_INTERVENTIONS_DONE'
const HUBPAY_STATUS_FIX_INTERVENTIONS_FAIL = 'FIX_INTERVENTIONS_FAIL'
export const HUBPAY_STATUS_ADD_COUPON_DONE = 'ADD_COUPON_DONE'
const HUBPAY_STATUS_ADD_COUPON_FAIL = 'ADD_COUPON_FAIL'
const HUBPAY_STATUS_SEND_PAYMENT_LINK_DONE = 'SEND_PAYMENT_LINK_DONE'
const HUBPAY_STATUS_SEND_PAYMENT_LINK_FAIL = 'SEND_PAYMENT_LINK_FAIL'
const HUBPAY_STATUS_CONDITIONS_ACCEPTED = 'CONDITIONS_ACCEPTED'
const HUBPAY_STATUS_PAYMENT_DONE = 'PAYMENT_DONE'
const HUBPAY_STATUS_PAYMENT_FAIL = 'PAYMENT_FAIL'
export const HUBPAY_STATUS_RECEIPT_PENDING = 'RECEIPT_PENDING'
export const HUBPAY_STATUS_RECEIPT_DONE = 'RECEIPT_DONE'
const HUBPAY_STATUS_RECEIPT_FAIL = 'RECEIPT_FAIL'
const HUBPAY_STATUS_SEND_RECEIPT_DONE = 'SEND_RECEIPT_DONE'
const HUBPAY_STATUS_SEND_RECEIPT_FAIL = 'SEND_RECEIPT_FAIL'
const HUBPAY_STATUS_COMPLETED = 'COMPLETED'
const HUBPAY_STATUS_CANCELLED = 'CANCELLED'

const HUBPAY_BADGE_STATUS_SUCCESS = 'success'
const HUBPAY_BADGE_STATUS_PROCESSING = 'processing'
const HUBPAY_BADGE_STATUS_ERROR = 'error'

export const hubPayStatusesMap = {
  [HUBPAY_STATUS_IMPORTED]: HUBPAY_BADGE_STATUS_PROCESSING,
  [HUBPAY_STATUS_CHOOSE_PAYMENT_METHOD_DONE]: HUBPAY_BADGE_STATUS_PROCESSING,
  [HUBPAY_STATUS_CHOOSE_PAYMENT_METHOD_FAIL]: HUBPAY_BADGE_STATUS_ERROR,
  [HUBPAY_STATUS_FIX_INTERVENTIONS_DONE]: HUBPAY_BADGE_STATUS_PROCESSING,
  [HUBPAY_STATUS_FIX_INTERVENTIONS_FAIL]: HUBPAY_BADGE_STATUS_ERROR,
  [HUBPAY_STATUS_ADD_COUPON_DONE]: HUBPAY_BADGE_STATUS_PROCESSING,
  [HUBPAY_STATUS_ADD_COUPON_FAIL]: HUBPAY_BADGE_STATUS_ERROR,
  [HUBPAY_STATUS_CONDITIONS_ACCEPTED]: HUBPAY_BADGE_STATUS_PROCESSING,
  [HUBPAY_STATUS_SEND_PAYMENT_LINK_DONE]: HUBPAY_BADGE_STATUS_PROCESSING,
  [HUBPAY_STATUS_SEND_PAYMENT_LINK_FAIL]: HUBPAY_BADGE_STATUS_ERROR,
  [HUBPAY_STATUS_PAYMENT_DONE]: HUBPAY_BADGE_STATUS_PROCESSING,
  [HUBPAY_STATUS_PAYMENT_FAIL]: HUBPAY_BADGE_STATUS_ERROR,
  [HUBPAY_STATUS_RECEIPT_PENDING]: HUBPAY_BADGE_STATUS_PROCESSING,
  [HUBPAY_STATUS_RECEIPT_DONE]: HUBPAY_BADGE_STATUS_PROCESSING,
  [HUBPAY_STATUS_RECEIPT_FAIL]: HUBPAY_BADGE_STATUS_ERROR,
  [HUBPAY_STATUS_SEND_RECEIPT_DONE]: HUBPAY_BADGE_STATUS_PROCESSING,
  [HUBPAY_STATUS_SEND_RECEIPT_FAIL]: HUBPAY_BADGE_STATUS_ERROR,
  [HUBPAY_STATUS_COMPLETED]: HUBPAY_BADGE_STATUS_SUCCESS,
  [HUBPAY_STATUS_CANCELLED]: HUBPAY_BADGE_STATUS_ERROR
}

export const PAYMENT_METHOD_CLICPAY = 'CLICKPAY'
export const PAYMENT_METHOD_BANKTRANSFER = 'BONIFICO BANCARIO'