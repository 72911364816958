import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Menu } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import config from 'Common/config'
const CustomMenu = props => {
  const location = useLocation()
  return (
    <Menu
      mode={'horizontal'}
      selectedKeys={[location.pathname]}
      style={{ lineHeight: '57px' }}>
      <Menu.Item key={'/'}>
        <Link to={'/'}>
          <FormattedMessage id={'app.menuItem.home'} />
        </Link>
      </Menu.Item>
      <Menu.Item key={'/archive'}>
        <Link to={'/archive'}>
          <FormattedMessage id={'app.menuItem.archive'} />
        </Link>
      </Menu.Item>
      <Menu.Item key="/receipt-todo">
        <Link to="/receipt-todo">
          <FormattedMessage id={'app.menuItem.receiptTodo'} />
        </Link>
      </Menu.Item>
      <Menu.Item key={'/report-accounting'}>
        <Link to={'/report-accounting'}>
          <FormattedMessage id={'app.menuItem.reportAccounting'} />
        </Link>
      </Menu.Item>
      <Menu.Item key={'/report-animation'}>
        <a
          href={config.DATASTUDIO_REPORT_ANIMATION_URL}
          target={'_blank'}
          rel={'noopener noreferrer'}>
          <FormattedMessage id={'app.menuItem.reportAnimation'} />
        </a>
      </Menu.Item>
    </Menu>
  )
}
export default CustomMenu
