import { GET_TEAMMATES, UPDATE_STORE_ID, VALIDATE_TOKEN } from '../action-types'

const initialState = { current: {}, teammates: [] }
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case VALIDATE_TOKEN:
      return { ...state, current: payload }
    case UPDATE_STORE_ID:
      return { ...state, current: { ...state.current, storeId: payload } }
    case GET_TEAMMATES:
      return { ...state, teammates: payload }
    default:
      return state
  }
}
