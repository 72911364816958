import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Avatar, Col, Input, List, Modal, Radio, Row, Space } from 'antd'
import {
  CheckOutlined,
  CreditCardOutlined,
  FileTextOutlined,
  MailOutlined,
  TagsOutlined
} from '@ant-design/icons'
import { connect } from 'react-redux'

import {
  getItem,
  getItemLogs,
  getItemStepper,
  choosePaymentMethod,
  getItemInterventions,
  fixItemIntervention,
  getItemCoupons,
  addCoupon,
  importItem,
  sendPaymentLink,
  manualSendPaymentLink,
  cancelPaymentLink,
  createAutomaticReceipt,
  manualReceipt,
  sendReceipt
} from 'Redux/actions/item-actions'

import Loader from 'Components/Loader'
import Button from 'Components/Button'
import Card from 'Components/Card'
import Stepper from 'Components/Stepper'
import LogsTable from 'Components/LogsTable'
import CustomerForm from 'Components/CustomerForm'
import { PageTitle } from 'Common/styled'

import {
  isMobile,
  DATE_FORMAT,
  buttonActionsMap,
  colors,
  HUBPAY_STATUS_ADD_COUPON_DONE,
  HUBPAY_STATUS_RECEIPT_PENDING,
  HUBPAY_STATUS_RECEIPT_DONE,
  PAYMENT_METHOD_CLICPAY,
  PAYMENT_METHOD_BANKTRANSFER,
  ITEM_TYPE_ORDER
} from 'Common/constants'
import { formatDate } from 'Common/utils'

class Item extends Component {
  static propTypes = {
    addCoupon: PropTypes.func,
    coupons: PropTypes.arrayOf(PropTypes.object),
    choosePaymentMethod: PropTypes.func,
    createAutomaticReceipt: PropTypes.func,
    fixItemIntervention: PropTypes.func,
    getItem: PropTypes.func,
    getItemCoupons: PropTypes.func,
    getItemInterventions: PropTypes.func,
    getItemLogs: PropTypes.func,
    getItemStepper: PropTypes.func,
    importItem: PropTypes.func,
    item: PropTypes.object,
    logs: PropTypes.array,
    manualReceipt: PropTypes.func,
    manualSendPaymentLink: PropTypes.func,
    sendPaymentLink: PropTypes.func,
    sendReceipt: PropTypes.func,
    stepper: PropTypes.object
  }

  state = {
    isLoading: false,
    isInterventionsModalLoading: false,
    isInterventionsModalVisible: false,
    selectedCoupon: {},
    selectedPaymentMethod: PAYMENT_METHOD_CLICPAY
  }

  componentDidMount () {
    const { match, history } = this.props
    const { itemFullNumber } = match.params
    if (itemFullNumber) {
      const [storeId, itemNumber] = itemFullNumber.split('-')
      this.getItem(+itemNumber, +storeId)
    } else {
      history.push('/')
    }
  }

  getItem = async (itemNumber, storeId) => {
    const { history, item, getItem, getItemLogs, getItemStepper, importItem } =
      this.props
    this.setState({ isLoading: true })
    const res = await getItem(
      itemNumber || item.itemNumber,
      storeId || item.storeId
    )
    if (res.status === 204) return history.push('/')

    await getItemLogs(res.itemNumber)
    await getItemStepper(res.itemNumber)
    await importItem(res.itemNumber, res.storeId, false)
    this.setState({ isLoading: false })
  }

  updateSelectedPaymentMethod = value =>
    this.setState({ selectedPaymentMethod: value })

  choosePaymentMethod = async () => {
    const { selectedPaymentMethod } = this.state
    const { choosePaymentMethod } = this.props
    this.setState({ isLoading: true })
    await choosePaymentMethod(selectedPaymentMethod)
    this.getItem()
  }

  getItemInterventions = async () => {
    const { getItemInterventions } = this.props
    this.setState({ isLoading: true })
    await getItemInterventions()
    this.setState({ isLoading: false })
    this.openInterventionsModal()
  }

  fixItemIntervention = async interventionId => {
    const { fixItemIntervention } = this.props
    this.setState({ isInterventionsModalLoading: true })
    await fixItemIntervention(interventionId)
    this.setState({ isInterventionsModalLoading: false })
  }

  finishInterventions = async () => {
    await this.fixItemIntervention()
    this.closeInterventionsModal()
    this.getItem()
  }

  getItemCoupons = async () => {
    const { getItemCoupons } = this.props
    this.setState({ isLoading: true })
    const coupons = await getItemCoupons()

    let newState = { isLoading: false }
    const defaultCoupon = coupons.find(c => c.isActive)
    if (defaultCoupon) {
      newState.selectedCoupon = {
        couponId: defaultCoupon.id,
        isActive: defaultCoupon.isActive
      }
    }

    this.setState(newState)
    this.openCouponsModal()
  }

  updateSelectedCoupon = couponId => {
    const { coupons } = this.props
    const selectedCoupon = coupons.find(c => c.id === couponId)
    this.setState({
      selectedCoupon: {
        couponId: selectedCoupon.id,
        isActive: selectedCoupon.isActive
      }
    })
  }

  addCoupon = async () => {
    const { selectedCoupon } = this.state
    const { addCoupon } = this.props
    this.setState({ isLoading: true })
    await addCoupon(selectedCoupon)
    this.getItem()
  }

  updateCustomerData = (_, values) => this.setState({ customer: values })

  sendPaymentLink = async () => {
    const { item, sendPaymentLink } = this.props
    const customer = item.customer || this.state.customer
    this.setState({ isLoading: true })
    await sendPaymentLink(customer)
    this.getItem()
  }

  manualSendPaymentLink = async () => {
    const { manualSendPaymentLink } = this.props
    this.setState({ isLoading: true })
    await manualSendPaymentLink()
    this.getItem()
  }

  cancelPaymentLink = async () => {
    const { cancelPaymentLink } = this.props
    this.setState({ isLoading: true })
    await cancelPaymentLink()
    this.getItem()
  }

  createAutomaticReceipt = async () => {
    const { createAutomaticReceipt } = this.props
    this.setState({ isLoading: true })
    await createAutomaticReceipt()
    this.getItem()
  }

  manualReceipt = async receiptId => {
    const { manualReceipt } = this.props
    this.setState({ isLoading: true })
    await manualReceipt(receiptId)
    this.getItem()
  }

  sendReceipt = async () => {
    const { item, sendReceipt } = this.props
    const customer = item.customer || this.state.customer
    this.setState({ isLoading: true })
    await sendReceipt(customer)
    this.getItem()
  }

  navigateToList = () => {
    const { history } = this.props
    history.goBack()
  }

  openModal = (icon, title, content, okCallback, okText) => {
    const { intl, isLoading } = this.props
    Modal.confirm({
      icon,
      title,
      content,
      okText: okText || intl.formatMessage({ id: 'item.modal.okText' }),
      cancelText: intl.formatMessage({ id: 'item.modal.cancelText' }),
      okButtonProps: { loading: isLoading },
      onOk: okCallback
    })
  }

  openPaymentMethodModal = () => {
    const { selectedPaymentMethod } = this.state
    const { intl, item } = this.props

    const icon = <CreditCardOutlined />
    const title = intl.formatMessage({ id: 'item.paymentMethodModal.title' })
    const content = (
      <Radio.Group
        defaultValue={selectedPaymentMethod}
        onChange={e => this.updateSelectedPaymentMethod(e.target.value)}>
        <Space direction={'vertical'}>
          <Radio value={PAYMENT_METHOD_CLICPAY}>
            {intl.formatMessage({
              id: 'item.paymentMethodModal.radio.clicpay'
            })}
          </Radio>
          {item.objectType === ITEM_TYPE_ORDER && (
            <Radio value={PAYMENT_METHOD_BANKTRANSFER}>
              {intl.formatMessage({
                id: 'item.paymentMethodModal.radio.bankTransfer'
              })}
            </Radio>
          )}
        </Space>
      </Radio.Group>
    )

    const okCallback = this.choosePaymentMethod
    const okText = intl.formatMessage({ id: 'item.paymentMethodModal.okText' })
    this.openModal(icon, title, content, okCallback, okText)
  }

  openCouponsModal = () => {
    const { selectedCoupon } = this.state
    const { intl, coupons } = this.props
    const hasCoupons = coupons.length > 0

    const icon = <TagsOutlined />
    const title = intl.formatMessage({ id: 'item.couponsModal.title' })
    const content = hasCoupons ? (
      <Radio.Group
        defaultValue={selectedCoupon.couponId}
        onChange={e => this.updateSelectedCoupon(e.target.value)}>
        <Space direction={'vertical'}>
          {coupons.map(c => (
            <Radio key={c.id} value={c.id}>
              <span dangerouslySetInnerHTML={{ __html: c.label }} />
            </Radio>
          ))}
          <Radio value={undefined}>
            {intl.formatMessage({ id: 'item.couponsModal.radio.noCoupons' })}
          </Radio>
        </Space>
      </Radio.Group>
    ) : (
      intl.formatMessage({ id: 'item.couponsModal.emptyText' })
    )

    const okCallback = this.addCoupon
    const okText = intl.formatMessage({
      id: hasCoupons
        ? 'item.couponsModal.okText.withCoupons'
        : 'item.couponsModal.okText.withoutCoupons'
    })
    this.openModal(icon, title, content, okCallback, okText)
  }

  openCustomerModal = () => {
    const { intl, item } = this.props
    const icon = <MailOutlined />
    const title = intl.formatMessage({ id: 'item.customerModal.title' })
    const content = (
      <>
        <CustomerForm
          customer={item.customer}
          intl={intl}
          onChange={this.updateCustomerData}
        />
        {item.customer ? (
          <small>{intl.formatMessage({ id: 'item.customerModal.text' })}</small>
        ) : null}
      </>
    )

    let okCallback = console.log
    if (item.hubPayStatus === HUBPAY_STATUS_ADD_COUPON_DONE)
      okCallback = this.sendPaymentLink
    if (
      item.hubPayStatus === HUBPAY_STATUS_RECEIPT_DONE ||
      item.hubPayStatus === HUBPAY_STATUS_RECEIPT_PENDING
    )
      okCallback = this.sendReceipt

    this.openModal(icon, title, content, okCallback)
  }

  openLinkModal = () => {
    const { intl, item } = this.props
    const icon = <MailOutlined />
    const title = intl.formatMessage({ id: 'item.linkModal.title' })
    const content = (
      <>
        <Input value={item.paymentRequestLink} />
        <small>{intl.formatMessage({ id: 'item.linkModal.text' })}</small>
      </>
    )

    const okCallback = this.manualSendPaymentLink
    const okText = intl.formatMessage({ id: 'item.linkModal.okText' })
    this.openModal(icon, title, content, okCallback, okText)
  }

  openReceiptModal = () => {
    const { intl } = this.props
    const icon = <FileTextOutlined />
    const title = intl.formatMessage({ id: 'item.receiptModal.title' })
    const content = (
      <Input
        id={'receipt'}
        placeholder={intl.formatMessage({
          id: 'item.receiptModal.placeholder'
        })}
      />
    )
    const okCallback = () =>
      this.manualReceipt(document.getElementById('receipt').value)

    this.openModal(icon, title, content, okCallback)
  }

  openInterventionsModal = () =>
    this.setState({ isInterventionsModalVisible: true })
  closeInterventionsModal = () =>
    this.setState({ isInterventionsModalVisible: false })

  renderInterventionsModal () {
    const { isInterventionsModalLoading, isInterventionsModalVisible } =
      this.state
    const { intl, interventions } = this.props
    return (
      <Modal
        visible={isInterventionsModalVisible}
        title={intl.formatMessage({ id: 'item.interventionsModal.title' })}
        onCancel={this.closeInterventionsModal}
        onOk={this.finishInterventions}
        cancelText={intl.formatMessage({ id: 'item.modal.cancelText' })}
        okText={intl.formatMessage({ id: 'item.interventionsModal.okText' })}
        okButtonProps={{
          disabled: interventions.some(int => int.status !== 'FINISHED')
        }}>
        {isInterventionsModalLoading ? (
          <Loader height={'50px'} />
        ) : (
          this.renderInterventionsList(interventions)
        )}
      </Modal>
    )
  }

  renderInterventionsList (interventions) {
    const { intl } = this.props
    return interventions.length > 0 ? (
      <List>
        {interventions.map(int => (
          <List.Item
            key={int.id}
            actions={[
              int.status === 'PENDING' ? (
                <Button
                  key={'resolve'}
                  type={'link'}
                  onClick={() => this.fixItemIntervention(int.id)}>
                  {intl.formatMessage({ id: 'item.button.resolve' })}
                </Button>
              ) : (
                <Avatar
                  size={'small'}
                  icon={<CheckOutlined />}
                  style={{ backgroundColor: colors.success }}
                />
              )
            ]}>
            <span dangerouslySetInnerHTML={{ __html: int.internalLabel }} />
          </List.Item>
        ))}
      </List>
    ) : (
      <FormattedMessage id={'item.interventionsModal.emptyText'} />
    )
  }

  renderItemCard () {
    const { intl, item } = this.props
    const { fullNumber, creationDate, totalAmount } = item
    return (
      <Col key={'item'} md={{ span: 5 }} xs={{ span: 24 }}>
        <Card
          title={intl.formatMessage({
            id: `item.type.${item.objectType}`
          })}
          highlight={fullNumber}
          data={[
            intl.formatMessage(
              { id: 'item.card.text.date' },
              { date: formatDate(creationDate, DATE_FORMAT) }
            ),
            intl.formatMessage(
              { id: 'item.card.text.totalAmount' },
              { totalAmount }
            )
          ]}
        />
      </Col>
    )
  }

  renderCustomerCard () {
    const { intl, item } = this.props
    const { customer } = item
    return (
      <Col key={'customer'} md={{ span: 5 }} xs={{ span: 24 }}>
        <Card
          title={intl.formatMessage({ id: 'item.card.title.customer' })}
          highlight={
            customer ? `${customer.firstName} ${customer.lastName}` : ''
          }
          data={
            customer
              ? [customer.phoneNumber, customer.email]
              : [intl.formatMessage({ id: 'item.card.text.noCustomer' })]
          }
        />
      </Col>
    )
  }

  renderStepper () {
    const { stepper } = this.props
    // Pass all the actions mapped as props to Stepper component
    const buttonActions = {}
    Object.values(buttonActionsMap).map(
      action => (buttonActions[action] = () => this[action]())
    )
    return (
      <Col key={'stepper'} md={{ span: 14 }} xs={{ span: 24 }}>
        <Stepper {...stepper} {...buttonActions} />
      </Col>
    )
  }

  render () {
    const { isLoading } = this.state
    const { logs } = this.props
    if (isLoading) return <Loader />

    return (
      <>
        <PageTitle>
          <FormattedMessage id={'item.title'} />
        </PageTitle>
        <Row gutter={[32, 16]}>
          {isMobile
            ? [
              this.renderItemCard(),
              this.renderCustomerCard(),
              this.renderStepper()
            ]
            : [
              this.renderItemCard(),
              this.renderStepper(),
              this.renderCustomerCard()
            ]}
        </Row>
        {!isMobile ? <LogsTable data={logs} /> : null}
        {this.renderInterventionsModal()}
      </>
    )
  }
}
const mapStateToProps = state => ({
  logs: state.item.logs,
  item: state.item.current,
  stepper: state.item.stepper,
  interventions: state.item.interventions,
  coupons: state.item.coupons
})
const mapDispatchToProps = {
  getItem,
  getItemLogs,
  getItemStepper,
  choosePaymentMethod,
  getItemInterventions,
  fixItemIntervention,
  getItemCoupons,
  addCoupon,
  importItem,
  sendPaymentLink,
  manualSendPaymentLink,
  cancelPaymentLink,
  createAutomaticReceipt,
  manualReceipt,
  sendReceipt
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Item))
