// USER
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'
export const UPDATE_STORE_ID = 'UPDATE_STORE_ID'
export const GET_TEAMMATES = 'GET_TEAMMATES'
export const GET_STORES = 'GET_STORES'

// ITEM
export const GET_ITEMS = 'GET_ITEMS'
export const MOVE_ITEMS = 'MOVE_ITEMS'
export const IMPORT_ITEM = 'IMPORT_ITEM'
export const UPDATE_ITEM = 'UPDATE_ITEM'
export const GET_ITEM = 'GET_ITEM'
export const GET_ITEM_LOGS = 'GET_ITEM_LOGS'
export const GET_ITEM_STEPPER = 'GET_ITEM_STEPPER'
export const CHOOSE_PAYMENT_METHOD = 'CHOOSE_PAYMENT_METHOD'
export const GET_ITEM_INTERVENTIONS = 'GET_ITEM_INTERVENTIONS'
export const FIX_ITEM_INTERVENTION = 'FIX_ITEM_INTERVENTION'
export const GET_ITEM_COUPONS = 'GET_ITEM_COUPONS'
export const ADD_COUPON = 'ADD_COUPON'
export const SEND_PAYMENT_LINK = 'SEND_PAYMENT_LINK'
export const MANUAL_SEND_PAYMENT_LINK = 'MANUAL_SEND_PAYMENT_LINK'
export const CANCEL_PAYMENT_LINK = 'CANCEL_PAYMENT_LINK'
export const CREATE_AUTOMATIC_RECEIPT = 'CREATE_AUTOMATIC_RECEIPT'
export const MANUAL_RECEIPT = 'MANUAL_RECEIPT'
export const SEND_RECEIPT = 'SEND_RECEIPT'
