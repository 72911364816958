import styled from 'styled-components'
import { Layout, Button } from 'antd'

import { colors } from './constants'

export const WhiteLayout = styled(Layout)`
  background: ${colors.white};
`
export const PaddedContent = styled(Layout.Content)`
  padding: 16px;
  min-height: calc(100vh - 112px);
  display: grid;
  gap: 16px;
  grid-template-rows: repeat(auto-fit, minmax(0, min-content));
`
export const UppercaseButton = styled(Button)`
  text-transform: uppercase;
`
export const PageTitle = styled.h1`
  color: ${colors.main};
  font-size: 32px;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
`
export const SmallTitle = styled.h2`
  color: ${colors.grey4};
`
export const ActionsRow = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
`
