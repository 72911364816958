import { GET_TEAMMATES, UPDATE_STORE_ID, VALIDATE_TOKEN } from '../action-types'
import axiosConfig from 'Common/axiosConfig'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const validateToken = token => async dispatch => {
  try {
    const res = await api.user.authenticate(token)
    const user = res.data
    axiosConfig.defaults.headers.Authorization = `Bearer ${token}`
    return dispatch({ type: VALIDATE_TOKEN, payload: user })
  } catch (error) {
    return handleServerError(error)
  }
}

export const updateStoreId = storeId => {
  return { type: UPDATE_STORE_ID, payload: +storeId }
}

export const getTeammates = () => async dispatch => {
  try {
    const res = await api.user.teammates()
    return dispatch({ type: GET_TEAMMATES, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}
