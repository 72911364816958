import {
  UPDATE_ITEM,
  GET_ITEM,
  GET_ITEM_LOGS,
  GET_ITEM_STEPPER,
  GET_ITEM_INTERVENTIONS,
  FIX_ITEM_INTERVENTION,
  GET_ITEM_COUPONS,
  GET_ITEMS
} from '../action-types'
import { ITEM_TYPE_ORDER } from 'Common/constants'

const initialState = {
  list: [],
  current: {
    objectType: ITEM_TYPE_ORDER
  },
  logs: [],
  stepper: {},
  interventions: [],
  coupons: []
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_ITEM:
    case GET_ITEM:
      return { ...state, current: { ...state.current, ...payload } }
    case GET_ITEM_LOGS:
      return { ...state, logs: payload }
    case GET_ITEMS:
      return { ...state, list: payload }
    case GET_ITEM_STEPPER:
      return { ...state, stepper: payload }
    case GET_ITEM_INTERVENTIONS:
    case FIX_ITEM_INTERVENTION:
      return { ...state, interventions: payload }
    case GET_ITEM_COUPONS:
      return { ...state, coupons: payload }
    default:
      return state
  }
}
